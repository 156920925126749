
.ad-banner > .card-body{
  padding: 0 !important;
}

.ad-banner .ad-banner-mobile{
  display: none;
}

@include media-breakpoint-down(xs){
  .ad-banner .ad-banner-desktop{
    display: none;
  }

  .ad-banner .ad-banner-mobile{
    display: block;
  }
}


/* Fix default width on datetimepicker */

.bootstrap-datetimepicker-widget.dropdown-menu{
  width: auto;
}
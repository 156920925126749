.credit-card {
  .credit-card-preview {
    margin: 130px auto 30px;
    position: relative;
    width: 460px;
    padding: 140px 45px 30px;

    .credit-card-box {
      width: 400px;
      height: 280px;
      position: absolute;
      top: -112px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);

      .flip {
        @include transition(all .6s);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        position: relative;
      }

      &.hover .flip {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
      }

      .front,
      .back {
        width: 400px;
        height: 250px;
        border-radius: 15px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        background: $primary; /* Old browsers */
        background: -moz-linear-gradient(45deg, lighten($primary, 5%) 0%, $primary 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, lighten($primary, 5%) 0%, $primary 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, lighten($primary, 5%) 0%, $primary 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#499bea', endColorstr='#207ce5', GradientType=1); /* IE6-9 fallback on horizontal gradient */
        position: absolute;
        color: #fff;
        top: 0;
        left: 0;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
        font-family: 'Inconsolata', monospace;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: url(../media/ccard/ccard_back.svg) no-repeat;
          background-size: cover;
          opacity: .05;
        }
      }

      .front {
        z-index: 2;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
      }

      .back {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);

        .credit-card-box-logo {
          top: 185px;
        }

      }

      .credit-card-box-logo {
        position: absolute;
        top: 9px;
        right: 20px;
        margin-top: 22px;

        &.visa {
          background: url(../media/ccard/ccard_visa.svg);
          width: 60px;
          height: 20px;
          background-size: cover;
        }

        &.master {
          background: url(../media/ccard/ccard_master.svg);
          width: 34px;
          height: 20px;
          background-size: cover;
        }

        &.maestro {
          background: url(../media/ccard/ccard_maestro.svg);
          width: 46px;
          height: 46px;
          background-size: cover;
          top: 0;
        }

        &.jcb {
          background: url(../media/ccard/ccard_jcb.svg);
          width: 46px;
          height: 46px;
          background-size: cover;
          top: 0;
        }

        &.dinersclub {
          background: url(../media/ccard/ccard_dinersclub.svg);
          width: 52px;
          height: 52px;
          background-size: cover;
          top: -5px;
        }

        &.laser {
          background: url(../media/ccard/ccard_laser.svg);
          width: 52px;
          height: 52px;
          background-size: cover;
          top: -5px;
        }

        &.discover {
          background: url(../media/ccard/ccard_discover.svg);
          width: 110px;
          height: 20px;
          background-size: cover;
        }

        &.amex {
          background: url(../media/ccard/ccard_amex.svg);
          width: 72px;
          height: 20px;
          background-size: cover;
        }

      }

      .credit-card-box-chip {
        position: absolute;
        width: 60px;
        height: 45px;
        top: 20px;
        left: 20px;
        background: url(../media/ccard/ccard_chip.svg) no-repeat;
        background-size: cover;
        @include border-radius(8px);
      }

      .credit-card-box-strip {
        background: -webkit-linear-gradient(315deg, #404040, #1a1a1a);
        background: linear-gradient(135deg, #404040, #1a1a1a);
        position: absolute;
        width: 100%;
        height: 50px;
        top: 30px;
        left: 0;
      }

      .credit-card-box-number {
        position: absolute;
        margin: 0 auto;
        top: 103px;
        left: 19px;
        font-size: 32px;
      }

      label {
        font-size: 10px;
        letter-spacing: 1px;
        text-shadow: none;
        text-transform: uppercase;
        font-weight: normal;
        opacity: 0.5;
        display: block;
        margin-bottom: 3px;
      }

      .credit-card-box-holder {
        div {
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .credit-card-box-holder,
      .credit-card-box-expiration-date {
        position: absolute;
        margin: 0 auto;
        top: 180px;
        left: 19px;
        font-size: 22px;
        text-transform: uppercase;
      }

      .credit-card-box-expiration-date {
        text-align: right;
        left: auto;
        right: 20px;
      }

      .credit-card-box-ccv {
        height: 36px;
        background: #fff;
        width: 91%;
        @include border-radius(5px);
        top: 110px;
        left: 0;
        right: 0;
        position: absolute;
        margin: 0 auto;
        color: #000;
        text-align: right;
        padding: 10px;

        label {
          margin: -25px 0 14px;
          color: #fff;
        }
      }
    }
  }
}
//
// Bootstrap Daterangepicker
//



// Base
.daterangepicker {
    padding: 0;
    margin: 0;
    width: auto;
    box-shadow: $dropdown-box-shadow;
    @include border-radius($border-radius);
    border: 0;
    font-family: $font-family-base;
    z-index: $zindex-dropdown;

    .modal-open & {
        z-index: $zindex-modal + 1;
    }

    &:after,
    &:before {
        display: none;
    }

    .ranges {
        ul {
            padding: 1rem 0;
            width: 175px;
        }

        li {
            padding: 0.7rem 1.75rem;
            font-weight: 500;
            font-size: 1rem;
            color: $dark-50;
            transition: color 0.3s ease;

            &:hover,
            &.active {
                background-color: $gray-100;
                color: $primary;
                transition: color 0.3s ease;
            }
        }
    }

    &.show-calendar {
        .ranges {
            border-right: 1px solid $gray-200;
            margin-top: 0;
            height: 297px;
        }
    }

    &.show-ranges {
        .drp-calendar.left {
            border-left: 0;
        }
    }

    .drp-buttons {
        padding: 1rem 1.75rem;
        border-top: 1px solid $gray-200;

       .btn {
            font-size: 0.9rem;
            font-weight: 500;
            padding: 0.5rem 1rem;
            @include border-radius($border-radius);
        }
    }

    .drp-selected {
        font-size: 0.9rem;
    }

    .drp-calendar {
        &.left,
        &.right {
            padding: 1rem 1rem;
        }

        &.left {
            border-left: 0 !important;
        }

        th,
        td {
            font-size: 1rem;
            font-weight: regular;
            width: 33px;
            height: 33px;
        }

        th {
            font-weight: 500;
            color: $dark-75;

            &.month {
                font-weight: 500;
                color: $dark-75;
            }

            &.next,
            &.prev {
                span {
                    border-width: 0 1px 1px 0;
                    border-color: $dark-50;
                }
            }

            &.next {
                span {
                    margin-right: 1px;
                }
            }

            &.prev {
                span {
                    margin-left: 1px;
                }
            }
        }

        td {
            color: $dark-50;

            &:hover {
                background-color: $gray-100;
            }

            &.available.off {
                color: $text-muted;
            }

            &.active {
                background-color: $primary !important;
                color: $primary-inverse !important;
                @include border-radius($border-radius);

                &.start-date {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &.end-date {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                &.start-date.end-date {
                    @include border-radius($border-radius);
                }
            }

            &.today,
            &.today.active {
                @include border-radius($border-radius);
                background: rgba($primary, 0.12) !important;
                color: $primary !important;
            }

            &.in-range.available:not(.active):not(.off):not(.today) {
                background-color: $gray-100;
                color: $dark-75;
            }
        }
    }

    select {
        @include border-radius($border-radius);
        background: transparent !important;
        border-color: $input-border-color !important;
        color: $input-color !important;
    }
}

@media (min-width: 730px) {
    .daterangepicker {
        &.show-calendar {
            .ranges {
                height: 297px !important;                
            }
        }
    }
}

// Mobile mode
@include media-breakpoint-down(sm) {
    .daterangepicker {
        &.show-calendar {
            .ranges {
                height: 245px;
            }
        }
    }
}

//
// Main Style
//

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Initialize
@import "init";

// 2: Bootstrap framework includes
@import "../../tools/node_modules/bootstrap/scss/bootstrap";

// 3: Components
@import "components/utilities",
"components/burger-icon",
"components/card",
"components/wizard",
"components/type",
"components/breadcrumb",
"components/nav",
"components/alert",
"components/buttons",
"components/code",
"components/dropdown",
"components/forms",
"components/grid",
"components/modal",
"components/progress",
"components/table",
"components/popover",
"components/tooltip",
"components/accordion",
"components/datatable",
"components/checkbox",
"components/radio",
"components/switch",
"components/option",
"components/dialog",
"components/strikethrough",
"components/separator",
"components/image-input",
"components/label",
"components/loader",
"components/symbol",
"components/pulse",
"components/page-loader",
"components/quick-search",
"components/scrolltop",
"components/spinner",
"components/sticky-toolbar",
"components/svg-icon",
"components/svg",
"components/timeline",
"components/timeline-2",
"components/timeline-3",
"components/timeline-4",
"components/timeline-5",
"components/timeline-6",
"components/overlay",
"components/wave",
"components/list",
"components/bullet",
"components/navi",
"components/ribbon",
"components/toggle";

// 4: Plugins
@import "vendors/plugins/blockui",
"vendors/plugins/bootstrap-datepicker",
"vendors/plugins/bootstrap-daterangepicker",
"vendors/plugins/bootstrap-markdown",
"vendors/plugins/bootstrap-maxlength",
"vendors/plugins/bootstrap-notify",
"vendors/plugins/bootstrap-select",
"vendors/plugins/bootstrap-session-timeout",
"vendors/plugins/bootstrap-switch",
"vendors/plugins/bootstrap-timepicker",
"vendors/plugins/bootstrap-touchspin",
"vendors/plugins/fontawesome5",
"vendors/plugins/ion-range-slider",
"vendors/plugins/jquery-repeater",
"vendors/plugins/perfect-scrollbar",
"vendors/plugins/sweetalert2",
"vendors/plugins/toastr",
"vendors/plugins/typeahead";

// 5: Layout
@import "layout/init";
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// Added by HB team
@import "app/statuses";
@import "app/ccard";
@import "app/custom";
@import "app/migration_cloudhosting";
@import "app/migration_bootstrap";
@import "app/migration_dnsservice";
@import "app/migration_reseller";
@import "app/migration_upgrades";
@import "pages/wizard/wizard-1";

// Added by 5G Networks
@import "../plugins/datatables/datatables.bundle.css";
@import "custom/custom";
//
// Bootstrap Timepicker Component
//




// Base
.bootstrap-touchspin {
    .form-control.bootstrap-touchspin-vertical-btn {
        @include border-radius($input-border-radius);
    }

    .input-group-btn-vertical {
        .btn {
            justify-content: center;
            text-align: center;

            &:first-child {
                border-bottom: 0;
            }

            i {
                padding: 0;
                font-size: 0.7rem;
                color: $dark-50;
            }
        }
    }

    .input-group-btn {
        .btn {
            font-size: 1.3rem;
            padding-top: 0;
            padding-bottom: 0;
            color: $dark-50;
        }
    }
}

@mixin make-grid-columns($i: 1, $list: ".span#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .span#{$i}";
  }
  #{$list} {
    position: relative;
    min-height: 1px;
    padding-left:  ceil(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));
  }
}
@mixin float-grid-columns($i: 1, $list: ".span#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .span#{$i}";
  }
  #{$list} {
    float: left;
  }
}
@mixin calc-grid-column($index, $type) {
  @if ($type == width) and ($index > 0) {
    .span#{$index}, .fow-fluid > .span#{$index} {
      width: percentage(($index / $grid-columns));
    }
  }
  @if ($type == input ) and ($index > 0) {
    input.span#{$index}, select.span#{$index}, textarea.span#{$index}, .uneditable-input.span#{$index} {
      min-width: $index * (map-get($container-max-widths, 'md') / 12);
    }
  }
  @if ($type == offset) {
    .offset#{$index} {
      margin-left: percentage(($index / $grid-columns));
    }
  }
  @if ($type == table) {
    .table .span#{$index} {
      width: percentage(($index / ($grid-columns * 2)));
      margin-left: 0;
      float: left;
    }
  }
}
@mixin loop-grid-columns($columns, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $type);
  }
}
@include make-grid-columns;
@include loop-grid-columns(12, width);
@include loop-grid-columns(12, offset);
//@include loop-grid-columns(24, table);
@include loop-grid-columns(24, input);
@include float-grid-columns();

.row-fluid {
  width: 100%;
}

input, select, textarea, .uneditable-input,
.row-fluid input, .row-fluid select, .row-fluid textarea,
.row-fluid .uneditable-input{
  &[class*="span"]{
    float: none;
    margin-left: 0;
  }
}
.cloud {

  display: flex;
  flex-wrap: wrap;

  > .wbox, > .widget { // widget append
    flex-basis: 100%;

    &.widget-append{
      padding-bottom: 30px;
    }

    form, #content-cloud, .cloud-widget { // widget append
      padding-bottom: 30px;
      border-bottom: 1px solid $border-color;
      width: 100%;

      > .header-bar {
        h3 {
          font-size: $h2-font-size;
          line-height: 1.1;
          font-weight: 400;
          color: $gray-700;
          margin: 0 0 $card-spacer-y;
        }
      }

      &::after{
        display: table;
        content: "";
        clear:both;
      }
    }
  }

  .dropdown-menu {
    li {
      border-bottom: 1px solid $border-color;

      &:last-child {
        border: none;
      }

      a {
        display: block;
        color: $gray-700;
        padding: 7px 30px;

        font-weight: $font-weight-light;
        font-size: $font-size-base;
        line-height: $line-height-base;

        &:hover {
          border: none;
          box-shadow: none;
          background: #eee;
        }
      }
    }
  }

  .left .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-spacer;
  }

  .cloud-top {

    display: block;
    width: 25%;
    line-height: $line-height-base;

    .cloud-header {
      h1 {
        font-size: $h2-font-size;
        line-height: 1.1;
        font-weight: 400;
        color: $gray-700;

        height: auto;
        margin: 0 0 $card-spacer-y;
        background: none;
        padding: 0;

        white-space: nowrap;
        overflow: visible;

        &.os-logo::before {
          font-family: 'FontAwesomeBrands';
          color: $primary;
          font-size: 24px;
          vertical-align: middle;
          margin-right: 1rem;
        }

        &.windows::before {
          content: "\f17a";
        }

        &.centos::before {
          content: "\f789";
        }

        &.ubuntu::before {
          content: "\f7df";
        }
      }

      .apeded {
        padding: 0;
        font-size: $font-size-sm;
      }
    }

    .cloud-nav-old {

      position: relative;
      max-height: none;
      float: none;

      overflow: hidden;
      overflow-anchor: none;

      min-height: 80px;

      .level-1 {
        float: none;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        flex-direction: row;

        overflow: visible;

        margin: 0;
        padding: 0;
        height: auto;
        border: none;
        box-shadow: 0 -2px 0px inset #eee;
        touch-action: auto;

        .menu-item, .dropdown-menu li {

          display: block;
          position: static;

          float: none;
          padding: 0px 30px;
          margin: 5px 0 0;
          border: none;
          white-space: nowrap;

          &:first-of-type {
            margin-left: 0;
            padding-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
            padding-right: 0;
          }

          &:hover {
            color: inherit;
            background: none;
          }

          > a {
            padding: 5px 0;

            border: none;
            border-bottom: 2px solid transparent;
            background: none;

            font-weight: $font-weight-light;
            font-size: $font-size-base;
            line-height: $line-height-base;

            height: auto;
            box-shadow: none;
            text-shadow: none;
            color: $gray-600;

            &:hover {
              color: $gray-400;
              font-weight: $font-weight-light;
              border-bottom: 2px solid $primary
            }
          }

          &.current-menu-item {
            order: 0;

            > a {
              color: $gray-700;
              font-weight: $font-weight-bold;
              border-bottom: 2px solid $primary
            }
          }

          .dropdown-menu {
            display: none !important;
          }

          &.show {
            > a {
              color: $gray-700;
              border-bottom: 2px solid $secondary
            }

            .dropdown-menu {
              display: flex !important;
              left: 0 !important;
              top: 100% !important;

              transform: translate3d(0px, -40px, 0px) !important;
              border: none;
              box-shadow: none;

              li {

              }
            }
          }
        }
      }
    }

    .cloud-nav {

      .level-1 {
        float: none;
        margin: 40px 50px 40px 0;
        padding: 0;

        .menu-item {
          display: block;
          float: none;
          margin: 0;
          background-color: $body-bg;

          a {
            display: block;
            height: auto;
            font-size: 14px;
            line-height: 36px;
            font-weight: $font-weight-light;
            color: $secondary;
            vertical-align: middle;

            margin: 0;
            padding: 0;

            text-align: left;
            background: none;
            border: none;
            box-shadow: none;
            border-radius: 0;
            text-shadow: none;

            &:hover, &:focus {
              background: none;
            }

          }

          &.current-menu-item > a, &:hover > a {
            color: $primary;
          }

          &.current-menu-item > a {
            font-weight: $font-weight-bold;
          }

          &.dropdown {

            .dropdown-toggle::after {
              float: right;
            }

            .dropdown-menu {
              position: static !important;
              transform: none !important;

              visibility: visible;
              opacity: 0;
              max-height: 0;

              float: none;
              box-shadow: none;
              border: none;

              margin: 0;
              padding: 0;

              li {
                border: none;

                a {
                  padding-left: 10px;
                  font-size: 12px;
                  line-height: 28px;
                  font-weight: $font-weight-light;
                  color: $secondary;
                }

                &:hover > a, &.active > a{
                  color: $primary
                }
                &.active > a{
                  font-weight: normal;
                }
              }
            }
          }

          &.show, &.current-menu-item {
            .dropdown-menu {
              opacity: 1;
              max-height: none;
            }
          }
        }
      }
    }
  }

  #content-cloud {
    margin: 70px 0 40px;
    width: 75%;

    .header-bar {

      height: auto;
      line-height: inherit;
      padding: 0;

      > h3 {
        font-size: $h4-font-size;
        font-weight: 400;
        line-height: 1.1;
        margin: .25rem 0 .5rem;
        padding: 0;

        color: $gray-700;
        text-shadow: none;

        float: none;
        display: block;
      }
    }

    ul.sub-ul {
      position: relative;
      float: none;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      flex-direction: row;

      li {
        display: block;
        padding: 0px 30px;
        margin: 5px 0 0;
        border: none;
        white-space: nowrap;

        a {
          color: $gray-700;
          padding: 5px 0;
          border-bottom: 2px solid transparent;

          font-weight: $font-weight-light;
          font-size: $font-size-base;
          line-height: $line-height-base;

          &:hover {
            color: $gray-700;
            border-bottom: 2px solid $primary;
          }

          &.active {
            font-weight: $font-weight-bold;
            color: $gray-700;
            border-bottom: 2px solid $primary;
          }
        }

        &:first-of-type {
          padding-left: 0;
          margin-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
          margin-right: 0;
        }
      }
    }

    .sub-ul{
      position: relative;
      margin: -40px 0 20px 0;
      padding: 0;
      height: auto;

      background: white;
      border: none;
      border-radius: 0;
      box-shadow: 0 -2px 0px inset #eee;

      select {
        padding: $input-padding-y $input-padding-x;
        line-height: $input-line-height;
        color: $input-color;
        background-color: $input-bg;
        font-size: 1rem;
        background-clip: padding-box;
        border: none;
        border-radius: $input-border-radius;
        box-shadow: $input-box-shadow;
        outline: none !important;
        height: $input-height;
      }
    }

    .content-bar {
      padding: 0;
    }
  }

  #lockable-vm-menu {
    overflow: visible;
    float: none;
    width: 100%;

    #lock {
      top: -50px;
      left: auto;
      right: 0;
      height: 40px !important;
      width: auto;
      min-width: 50%;

      padding: 0;
      z-index: 20;

      text-align: right;
      white-space: nowrap;
      display: none;

      &.active{
        display: block;
      }
    }
  }

  //overpower responsive.css
  #lockable-vm-menu #vm-menu, #vm-menu {

    position: absolute;
    top: -52px;
    right: 0;
    z-index: 15;

    max-height: 48px;
    max-width: 50%;
    width: 50%;

    height: auto;

    overflow: visible;
    margin: 0;

    display: flex;
    justify-content: flex-end;
    transition: all 0.25s ease-in-out;

    li {
      //common for #vm-menu > li, and #vm-menu .dropdown-menu > li

      background: white;
      float: none;
      margin: 0;
      transition: all .25s ease-in-out;

      a {
        background: none;
        height: auto;
        border: none;
        box-shadow: none;
        border-radius: 0;

        width: auto;
        min-width: 0!important;
        padding: 7px 20px;

        font-weight: $font-weight-light;
        font-size: $font-size-base;
        line-height: $line-height-base;
        text-align: left;
        white-space: nowrap;

        img, br, a .vpsdo-icon {
          display: none;
        }

        &:hover {
          border: none;
          box-shadow: none;
          background: #eee;
        }

        &.dropdown-toggle{
          padding: 7px 10px;
        }

      }
    }

    > li {
      width: auto!important;

      > a {
        border-radius: $border-radius;
      }
    }

    .dropdown-menu {
      .vpsdo-icon {
        display: none;
      }

      .vpsdo-decr{
        display: inline;
      }
    }
  }

  .vm-menu-dropdown > .dropdown-toggle::after{
    border: none;
    display: inline-block;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;

    font-size: 1.5rem;
    line-height: 1;
    vertical-align: middle;

    font-family: "Material Icons";
    content: "more_vert";
  }

  #lock.active + #vm-menu{
    visibility: hidden;
  }

  .vpsdo-icon {
    display: inline-block;
    text-align: center;
    vertical-align: middle;

    &::before {
      font-family: "Material Icons";
      font-weight: 900;
      margin-right: 10px;
    }

    &.src-poweroff::before {
      content: "power_settings_new";
    }

    &.src-24_terminal::before {
      content: "computer";
    }

    &.src-24_arrow-circle::before {
      content: "autorenew";
    }

    &.src-24_equalizer::before {
      font-family: "FontAwesome";
      content: "\f1de";
    }

    &.src-24_blueprint::before {
      content: "build";
    }
  }

  .vm-details {
    margin: 1rem 0;
  }

  .vm-details-table {
    display: block;
    margin: 0;
    padding: 0;
  }

  .iphone_switch,
  .state_switch {
    width: auto;
    border: solid 1px $border-color;

    display: inline-block;
    border-radius: 13px;
    box-shadow: none;

    min-width: 42px;
    height: 26px;
    line-height: 26px;
    padding: 0 12px;

    color: $gray-700;

    &.on, &.off,
    &.state_switch_off, &.state_switch_on,
    &.iphone_switch_on, &.iphone_switch_off {
      width: auto;
      background: none;
      box-shadow: none;
      text-shadow: none;
      margin: 0;
      border: solid 1px $border-color;
      transition: all .25s ease-in-out;

      text-transform: uppercase;
      font-family: $font-family-monospace;
      font-size: 12px;
      font-weight: bold;
      line-height: 24px;

      text-overflow: ellipsis;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        right: auto;

        box-shadow: none;
        transition: all .25s ease-in-out;

        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: white;
      }

      &::before {
        content: none;
      }
    }

    &.on, &.state_switch_on, &.iphone_switch_on {
      border-color: $success;
      background-color: $success;
      color: $white;
      padding-right: 30px;

      &:hover {
        color: $white;
      }

      &::after {
        left: calc(100% - 24px);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      }
    }

    &.off, &.state_switch_off, &.iphone_switch_off {
      background-color: #fafafa;
      color: $gray-700;
      padding-left: 30px;

      &::after {
        left: 0px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      }
    }
  }

  table.checker,
  table.tonapp,
  table.ttable,
  table.data-table,
  table.data-table.backup-list,
  table.whitetable {
    color: $gray-700;
    border-collapse: separate;
    font-size: $font-size-base;
    border: none;

    thead {
      background: none;
      border: none;

      tr {
        box-shadow: none;
        color: $gray-700;
        background: transparent;

        td, th {
          font-weight: $table-head-font-weight;
          font-size: $table-head-font-size;
          border-bottom-width: 1px;
          padding-top: 1rem;
          padding-bottom: 1rem;
          color: $text-muted;
          text-transform: uppercase;
          text-wrap: nowrap;

          &.sorting {
            background-position: calc(100% - 10px) center;;
          }
        }
      }
    }

    tbody {
      tr {
        background: none;

        td {
          vertical-align: middle;
          padding: 10px 20px;
          border: none;
          height: auto;
          border-bottom: $table-border-width solid $table-border-color;

          &.bigger {
            color: $gray-700;
            font-size: $font-size-base;
            font-weight: normal;
          }
        }

        &:hover {
          td {
            background: none;
          }
        }
      }
    }

    tbody:last-child tr:last-child td {
      border-bottom: none;
    }
  }


  table.vm-details, table.vm-details-table {
    border: none;

    > tbody, > tbody > tr, > tbody > tr > td {
      display: block;
      border: none;
      background: none;

      line-height: $line-height-base;
      font-size: $font-size-base;

      width: 100%;
      height: auto;
      padding: 0;
      margin: 0;
    }

    .vm-details-table > tbody {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      border: $border-width solid $border-color;
      border-radius: $border-radius;

      margin-bottom: 1rem;

      .vm-details-row {
        padding: 1.4em;
        width: 100%;
        //margin-bottom: 2rem;

        &:nth-child(2n-1):not(:first-child)::before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          margin-top: -1rem;
          width: 100%;
          height: 0;
          border-top: $border-width solid $border-color;
        }

        .vm-details-label {
          font-weight: 500;
          font-size: 80%;
          margin-bottom: .5rem;
          border-bottom: none;
        }

        .vm-details-value {
          font-size: $font-size-sm;
          border-bottom: none;
        }
      }
    }
  }

  @media (min-width: 768px) {
    table.vm-details, table.vm-details-table {
      .vm-details-table > tbody {
        .vm-details-row {
          padding: 1.4em;
          width: 50%;
        }
      }
    }
  }

  table.table-rw-stack {
    margin: 20px 0;

    &, tbody, tr, tr td {
      background: none;
      border: none;
      display: block;
      padding: 0;
    }

    tbody {
      tr {
        margin-bottom: $form-group-margin-bottom;

        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-auto-flow: column;

        td {
          text-align: left;
          padding: 0;
          display: block;
          border: none;
          font-size: $font-size-base;
          font-weight: $font-weight-base;
          line-height: $line-height-base;
          margin-bottom: $label-margin-bottom;
          max-width: none;
        }
      }
    }

    input[type=text], input[type=password], select {
      width: 100%;
    }
  }

  table.table-label-value {
    > tbody > tr > td:first-child {
      font-weight: $font-weight-bold;
    }
  }

  #content-cloud .checker td {
    background: none;
  }

  .slabel{
    display: block;
    float: none;
    padding-left: 0;
  }

  .input-with-slider {
    white-space: nowrap;

    .ui-slider {
      margin: 20px;
      float: none;
      width: auto;
      flex-grow: 1;
    }

    .slider-value {
      min-width: 140px;
      display: block;
      order: 2;

      input[type=text] {
        width: auto;
        min-width: 80px;
      }
    }

    &::before, &::after {
      display: table;
      content: "";
      clear: both;
    }
  }

  @mixin cloud-btn-styles {
    display: inline-block;
    font-weight: $btn-font-weight;
    color: $body-color;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    background-image: none;
    letter-spacing: 0.2px;

    @include button-styles(small_control);
    @include button-size($btn-padding-y*0.6, $btn-padding-x*0.6, 12px, $btn-line-height, $btn-border-radius);
  }

  .report {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 0;


    border-radius: $border-radius;
    box-shadow: none;

    span {
      font-size: $font-size-sm;
    }

    .value {
      font-size: $font-size-lg;
      padding: 0;
    }

    .button {
      padding: 10px;
      margin: 10px;
      width: auto;

      color: $gray-700;
      border: 1px solid $border-color;

      display: flex;
      flex-flow: column;
      justify-content: center;

      &.green {
        box-shadow: none;
        border: 1px solid $primary;
        background: $primary;
        color: white;
      }
    }
  }

  .metered-history {
    display: flex;
    flex-flow: column;

    .right {
      order: 2;
    }

    .history-opt {
      display: inline-block;

      select {
        width: auto !important;
        height: $input-height-sm;
        padding: $input-padding-y-sm $input-padding-x-sm;
        font-size: $input-font-size-sm;
        line-height: $input-line-height-sm;
        @include border-radius($input-border-radius-sm);
      }
    }
  }

  .dataTables_wrapper {
    background: none;
    border: none;
    margin: 10px 0 30px;

    .paginate_button {
      @include cloud-btn-styles;
      @include button-variant(map-get($theme-colors, 'secondary'), map-get($theme-colors, 'secondary'));

      color: white;
    }
  }

  .vm-line .actions {
    white-space: nowrap;
  }

  .small_control, a.label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    text-transform: none;
    margin: 0.2rem;

    @include cloud-btn-styles;
    @include button-variant(map-get($theme-colors, 'secondary'), map-get($theme-colors, 'secondary'));

    &.small_delete, &.delete {
      @include button-variant(map-get($theme-colors, 'danger'), map-get($theme-colors, 'danger'));
    }

    &::before {
      font-family: "FontAwesome";
      font-size: 80%;
      line-height: $line-height-sm;

      display: inline;
      font-weight: 900;

      //vertical-align: middle;
      text-align: center;
    }

    &.small_pencil::before {
      content: "\f303  ";
    }

    &.small_delete::before {
      content: "\f057  ";
    }

    &.small_up::before {
      content: "\f077  ";
    }

    &.small_down::before {
      content: "\f078  ";
    }

    &.small_save::before {
      content: "\f0c7  ";
    }

    &.small_backup::before {
      content: "\f1c6  ";
    }

    &.small_backup_schedule::before {
      content: "\f017  ";
    }

    &.small_backup_restore::before {
      content: "\f49e  ";
    }

    &.small_backup_convert::before {
      content: "\f03e  ";
    }
  }

  .ico {

    background: none;
    float: none;
    display: inline-block;
    font-size: 0;
    text-indent: 0;

    vertical-align: middle;
    height: auto;
    width: auto;

    &::before {
      font-size: 1rem;
      font-weight: 900;
      display: inline-block;
      font-family: "FontAwesome";

    }

    &.ico_wrench::before {
      content: "\f0ad";
      font-size: 0.9rem;
    }

    &.ico_cross::before {
      content: "\f00d";
    }
  }

  .progress-bar{
    border: none;
    height: auto;
    line-height: $line-height-base;
  }

  :not(.progress) > .progress-bar {

    border: none;
    height: $progress-height;
    overflow: hidden;
    font-size: $progress-font-size;
    background-color: $progress-bg;

    @include border-radius($progress-border-radius);
    @include box-shadow($progress-box-shadow);

    .bar {
      left: 0;
      border-radius: 0;
      background: $progress-bar-bg;
      height: $progress-height;

      &.green {
        border: none;
        background: $success;
      }

      &.red {
        border: none;
        background: $danger;
      }
    }
  }

  .resources_box {
    position: static;
    margin: 20px 0;
    padding: 20px 0;

    width: auto;
    border: 1px solid $border-color;
    border-left: none;
    border-right: none;
    background: none;
    box-shadow: none;

    em {
      font-size: $h6-font-size;
      font-style: normal;
      color: $gray-700;
    }

    table, td {
      border: none;
      height: auto;
      display: block;
      padding: 0;
      width: auto;
    }

    table {
      margin-bottom: 10px;

      tbody {
        display: flex;
        flex-wrap: wrap;

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        grid-gap: $spacer;

        tr {
          display: flex;
          flex-grow: 1;
          justify-content: center;

          margin: 0;
          border: 1px solid $border-color;
          box-shadow: none;

          td {
            padding: 20px 10px;
            width: 50%;

            &:first-child, &:last-child {
              margin: 0;
              padding: 20px 10px;
              border: none;
            }

            &:first-child {
              text-align: right;
            }

            &:last-child {
              text-align: left;
            }
          }
        }
      }
    }
  }

  .label {
    width: auto;
  }

  .vm-tags {
    .vm-tag, .label {

      vertical-align: middle;
      border-radius: 0.43rem;
      padding: 6px;

      text-shadow: none;

      font-weight: 500;
      font-size: 10px;
      text-transform: uppercase;
    }
  }

  span.Active {
    color: $success
  }

  .slider {
    @include slider;
  }

  .notice {
    @extend .alert;
    @include alert-variant($warning, $warning, $warning);

    h1, h2, h3, h4, h5, h6 {
      color: darken($warning, 5%);
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
    }
  }

  /* ONAPP SPECIFIC */
  .firewall-interfaces {
    display: flex;
    clear: both;
    width: 100% !important;

    div.bg {
      margin: 0;
      padding: 0;
      width: 100%;

      border: none;
      border-bottom: 1px solid $border-color;

      background: none;
      display: flex;

      justify-content: flex-start;
      align-items: center;

      > * {
        width: auto;
        margin: 20px;
      }
    }
  }

  /* SOLUSVM SPECIFIC */
  .vm-details .status-bar {
    display: flex;
    justify-content: space-between;
    border: none;
    height: $progress-height;
    overflow: hidden;
    padding: 0px 5px;

    font-size: $progress-font-size;
    background: $gray-500;;

    @include border-radius($progress-border-radius);
    @include box-shadow($progress-box-shadow);

    .usage{
      left: 0; top: 0;
      border: none;
      border-radius: 0;
      background: $progress-bar-bg;
      height: $progress-height;
    }
  }

  .nopadding > div[style*=padding]{
    padding-left: 0!important;
  }

  pre.code {
    border-radius: $border-radius;
    background: $code-bg;
    padding: $code-padding;
  }

  select, input[type=text] {
    &.span1 {
      min-width: 60px !important;
    }

    &[class*=span] {
      min-width: 120px;
    }
  }

  .vm-password-input {
    display: flex;
    align-items: center;
    .vm-password-mask{
      min-width: 36px;
      text-align: center;
    }
  }
}

.modal {
  h4 {
    margin-left: 0;
  }

  input[type=text].form-control {
    @extend %form-control-styles;
    font-size: $input-font-size;
  }

}

.control-group {
  &.error {
    input,
    select,
    textarea {
      color: $danger !important;
      border-color: $danger !important;
    }
  }
}

@media (max-width: 993px) {

  .cloud {
    .cloud-top,
    #content-cloud {
      width: 100%;
      margin: 20px 0;
    }
    .cloud-top {
      .cloud-nav ul {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        .menu-item {
          &:first-of-type {
            margin-left: 0!important;
            padding-left: 0!important;
          }

          padding: 10px 30px;
          margin: 5px 0;
          border: none;
          margin-bottom: 0;
        }
      }
    }

    #content-cloud {

    }

    #vm-menu{
      .vpsdo-icon::before{
        margin-right: 0;
      }
      .vpsdo-decr{
        display: none;
      }
    }



    table.table-rw-stack {
      margin: 20px 0;
      tbody {
        tr {
          display: flex;
          flex-direction: column;
          td {
            &:nth-child(even) {
              padding-bottom: 30px;
            }
          }
        }
      }
    }
  }
}
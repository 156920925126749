html {
  &.loading {
    opacity: 1;
    pointer-events: all !important;
  }
}

.account-content {
  .widget {

    table {
      thead {
        th[width="5%"] {
          width: 10%;
        }
      }
      tbody td {
        padding: 10px;
      }
      input.span1,
      input.span2,
      input.span3,
      input.span4,
      input.span5,
      input.span6,
      select.span1,
      select.span2,
      select.span3,
      select.span4,
      select.span5,
      select.span6 {
        width: auto;
        max-width: 100%;
      }
      .table {
        width: 100%;
        td {
          line-height: 40px;
        }
      }

      tr.disab {
        input,
        select {
          width: auto;
          max-width: 100%;
        }
      }
    }

    .control-group {
      margin-top: 10px;
      overflow: hidden;
      display: block;
      .span6,
      .span4 {
        padding-left: 0;
      }
    }


    & > div {
      margin-bottom: 20px;
    }
    .body-content {
      margin: 20px 0;
      .description {
        margin: 30px 0;
      }
    }
    #mainform {
      .control-group {
        .btn {
          margin-top: 10px;
        }
      }
    }
    .section {
      width: 100%;
      margin-bottom: 20px;
      .form-group {
        .row-fluid {
          overflow: hidden;
          .span6, .span2 {
            padding-left: 0;
          }
        }
      }
    }
    #billing_info {
      h2 {
        display: block;
        width: 100%;
        margin-bottom: 20px;
      }
      .nav {
        width: 100%;
        margin-bottom: 20px;
        li {
          padding: 10px 20px;
          &:first-of-type {
            padding-left: 0;
          }
          a {
            padding-bottom: 10px;
          }
          &.active {
            a {
              border-bottom: 2px solid $primary;
            }
          }
        }
      }

      #widget-section {
        width: 100%;
        table {
          td[align=right],
          th[align=right] {
            text-align: left !important;
          }
        }
      }
    }

    a.btn.btn-primary:not([href]):not([tabindex]) {
      color: white;
    }
  }
}

$input-hover-bg: $input-bg;
$input-hover-border-color: darken($input-border-color, 10%);
$input-hover-color: $input-color;

$input-focus-bg: $input-bg;
$input-focus-border-color: darken($input-border-color, 20%);
$input-focus-color: $input-color;

//use this only to places that are not available, where the class .form-control can not be added
//just type ` @extend %form-control-styles ` in the element styles
%form-control-styles {
  position: relative;
  padding: $input-padding-y $input-padding-x;
  line-height: $input-line-height;
  background-color: $input-solid-bg;
  color: $input-solid-color;
  background-clip: padding-box;
  @include border-radius($input-border-radius);
  box-shadow: $input-box-shadow;
  outline: none !important;
  height: $input-height;
  border-color: $input-solid-bg;
  @include placeholder($input-solid-placeholder-color);
  transition: $transition-input;

  &:active,
  &.active,
  &:focus,
  &.focus {
    background-color: $input-solid-bg-focus;
    border-color: $input-solid-bg-focus;
    color: $input-solid-color;
    transition: $transition-input;
  }

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    opacity: 1;
  }

  &:hover {
    border-color: $input-hover-border-color;
    outline: 0;
    background-color: $input-hover-bg;
    color: $input-hover-color;
  }

  &:not([disabled], [readonly], .is-invalid, .form-control-noborders):hover,
  &:not([disabled], [readonly], .is-invalid, .form-control-noborders):focus,
  &:not([disabled], [readonly], .is-invalid, .form-control-noborders):active {
    background: $input-focus-bg;
    border: $border-width solid darken($input-focus-border-color, 12%) !important;
    box-shadow: $input-box-shadow;
  }
}


//use this only to places that are not available, where the classes .btn .btn-default can not be added
//just type ` @extend %button-styles ` in the element styles
@mixin button-styles($type) {
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border-width: 0;
  letter-spacing: 0.4px;
  @include button-size($btn-padding-y, $btn-padding-x, 13px, $btn-line-height, $btn-border-radius);
  &.#{$type}-sm,
  &.#{$type}-xs {
    letter-spacing: 0.2px;
    @include button-size($btn-padding-y*0.6, $btn-padding-x*0.6, 12px, $btn-line-height, $btn-border-radius);
  }
  &.#{$type}-lg {
    letter-spacing: 0.6px;
    @include button-size($btn-padding-y*1.4, $btn-padding-x*1.4, 14px, $btn-line-height, $btn-border-radius);
  }
  &.#{$type}-long {
    max-width: 250px;
    width: 100%;
  }
  @include transition($btn-transition);
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }
  &.#{$type}-border1px {
    border-width: 1px;
  }
  &.#{$type}-border2px {
    border-width: 2px;
  }
  &.#{$type}-rounded {
    border-radius: 1em;
  }
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
}

@mixin slider {
  background: $primary-light !important;
  height: 4px;
  padding: 1px;
  position: relative;
  margin: 20px;
  width: 100%;
  cursor: pointer;
  clear: left;
  min-width: 120px;
  border: none !important;

  .ui-slider-handle {
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    margin-top: -6px;
    margin-left: -10px;
    @include border-radius(50%);
    background: $primary;
    box-shadow: 0 3px 5px rgba($primary, .2);
    background-image: none !important;
    cursor: pointer;
    outline: none;
    border: none;
  }
  .ui-widget-header {
    background: $primary !important;
  }
}


@mixin badge-styles($type) {
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius($border-radius);
  padding: 6px;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;

  &.#{$type}-details {
    border: $border-width solid $border-color;
    background: $body-bg;
    color: $gray-500;
    font-weight: normal;
  }

  &.#{$type}-Active,
  &.#{$type}-Accepted,
  &.#{$type}-Paid,
  &.#{$type}-Receiptpaid,
  &.#{$type}-Opened,
  &.#{$type}-Open,
  &.#{$type}-success.#{$type}-styled {
    background-color: theme-light-color('success');
    color: $success;
  }
  &.#{$type}-Pending,
  &.#{$type}-Sent,
  &.#{$type}-In-Progress,
  &.#{$type}-primary.#{$type}-styled {
    background-color: theme-light-color('info');
    color: $info;
  }
  &.#{$type}-Unpaid,
  &.#{$type}-Receiptunpaid,
  &.#{$type}-Terminated,
  &.#{$type}-danger.#{$type}-styled {
    background-color: theme-light-color('danger');
    color: $danger;
  }
  &.#{$type}-Suspended,
  &.#{$type}-Answered,
  &.#{$type}-warning.#{$type}-styled {
    background-color: theme-light-color('warning');
    color: $warning;
  }
  &.#{$type}-Client-Reply {
    background-color: theme-light-color('purple');
    color: $purple;
  }
  &.#{$type}-Canceled,
  &.#{$type}-Draft,
  &.#{$type}-Dead,
  &.#{$type}-Cancelled,
  &.#{$type}-Receiptcanceled,
  &.#{$type}-Close,
  &.#{$type}-Closed,
  &.#{$type}-Creditnote,
  &.#{$type}-Fraud,
  &.#{$type}-secondary.#{$type}-styled {
    background-color: theme-light-color('secondary');
    color: $gray-600;
  }
  &.#{$type}-status {
    background-color: $gray-300;
    color: $gray-600;
  }

  &.#{$type}-pill {
    padding-right: $badge-pill-padding-x;
    padding-left: $badge-pill-padding-x;
    @include border-radius($badge-pill-border-radius);
  }
}

.label:not(.label-dot) {
  @include badge-styles(label);
}

table thead tr a[data-sorter="orderby"] {
  color: $gray-500;

  &.active {
    color: $primary;

    i {
      color: $primary
    }
  }

  &:hover {
    color: $gray-600;
  }
}

.slides, .ui-slider {
  @include slider;

  .sl,
  .sr {
    display: none !important;
  }
}

.slider-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .slider-value-indicator {
    white-space: nowrap;
  }
}

.domain-box {
  .domain-sld {
    flex: 4 0 0;
    min-height: 100px;
  }

  .domain-tld {
    flex: 2 0 0;
    user-select: none;
    text-align: left;
    max-height: 300px;
    min-height: 200px;
    position: relative;

    .domain-tld-search {
      @include border-radius(0);
      border: none;
      border-bottom: $border-width solid $border-color;
    }

    .ps__rail-y {
      background-color: #fafafa;

      .ps__thumb-y {
        background-color: #ccc;
      }
    }

    .domain-tld-checkbox-item,
    .domain-tld-checkbox-all {
      color: $gray-600;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: $gray-100;
      }

      .domain-tld-checkbox-icon {
        visibility: hidden;
      }

      &.checked {
        color: $gray-800;
        background-color: $gray-200;

        .domain-tld-checkbox-icon {
          visibility: visible;
        }
      }
    }
  }
}

.form-label-group,
.form-group {
  position: relative;
}

.vtip_description {
  &.vtip_description_signup_form_label {
    position: absolute;
    right: 10px;
    top: 0;
    background-color: transparent;
    padding: 15px;
  }
}

.wbox {
  > .wbox-header,
  > .wbox_header {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    padding: $card-spacer-y $card-spacer-x;
    background-color: transparent;
    margin: $card-header-spacer-y;
    margin-left: 0;
    font-weight: 500;
    font-size: 1.275rem;
    color: $dark;
  }

  > .wbox-body,
  > .wbox-content,
  > .wbox_content {
    padding: $card-spacer-y $card-spacer-x;
  }

  > .wbox-footer,
  > .wbox_footer {
    background-color: transparent;
  }
}

.navi-service-menu {
  a {
    .widg-image {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      margin-right: 0.5rem !important;

      &.widg_default::before {
        content: "\f015";
      }

      &.widg_resetpass::before {
        content: "\f502";
      }

      &.widg_logindetails::before {
        content: "\f505";
      }

      &.widg_cancellation::before {
        content: "\f057";
      }

      &.widg_diskbandusage::before {
        content: "\f538";
      }

      &.widg_accesscpanel::before {
        content: "\f51c";
      }

      &.widg_accesswebmail::before {
        content: "\f0e0";
      }

      &.widg_contactinfo::before {
        content: "\f509";
      }

      &.widg_dnsmanagement_widget::before {
        content: "\f542";
      }

      &.widg_domainforwarding::before {
        content: "\f277";
      }

      &.widg_emailforwarding::before {
        content: "\f362";
      }

      &.widg_eppcode::before {
        content: "\f084";
      }

      &.widg_registernameservers::before {
        content: "\f6ff";
      }

      &.widg_reload::before,
      &.widg_autorenew::before {
        content: "\f01e";
      }

      &.widg_backup::before {
        content: "\f1c0";
      }

      &.widg_reglock::before {
        content: "\f13e";
      }

      &.widg_nameservers::before {
        content: "\f542";
      }

      &.widg_ip_manage::before {
        content: "\f126";
      }

      &.widg_idprotection::before {
        content: "\f3ed";
      }

      &.widg_accountgraphs::before {
        content: "\f55b";
      }

      &.widg_billingcontact::before {
        content: "\f507";
      }

      &.widg_changebillingcycle::before {
        content: "\f155";
      }

      &.widg_power_usage::before {
        content: "\f0e7";
      }

      &.widg_changelabel::before {
        content: "\f303";
      }

      &.widg_changeowner::before {
        content: "\f4ff";
      }

      &.widg_directlink::before {
        content: "\f0c1";
      }

      &.widg_editformfield::before {
        content: "\f5ae";
      }

      &.widg_manualrenew::before {
        content: "\f04b";
      }

      &.widg_meteredwidget::before {
        content: "\f080";
      }

      &.widg_quotausage::before {
        content: "\f1ce";
      }

      &.widg_console::before {
        content: "\f120";
      }

      &.widg_relatedservices::before {
        content: "\f126";
      }

      &.widg_serviceautorenew::before {
        content: "\f079";
      }

      &.widg_sshkeys::before {
        content: "\f084";
      }

      &.widg_service_dnssec::before {
        content: "\f084";
      }

      &.widg_contracts::before {
        content: "\f0f2";
      }

      &.widg_pka_bind::before {
        content: "\f126";
      }

      &.widg_pka_license::before {
        content: "\f05a";
      }

      &.widg_dci2_network::before {
        content: "\f085";
      }

      &.domain_dnssec::before {
        content: "\f085";
      }

      &.reverse_dns_colo::before,
      &.reverse_dns::before {
        content: "\f085";
      }
    }
  }
}
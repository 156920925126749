//
// Bootstrap Maxlength
//


.bootstrap-maxlength {
    z-index: 1040 !important;

    &.kt-badge {
        display: inline-flex !important;
    }

    .modal & {
        z-index: 1060 !important;
    }
}

// prepare styles for label or badge or another similar element/
// use @include badge-styles(badge)
// or  @include badge-styles(label)
@mixin badge-styles($type) {
  &.#{$type}-details {
    border: $border-width solid $border-color;
    background: $body-bg;
    color: $gray-500;
    font-weight: normal;
  }

  &.#{$type}-Active,
  &.#{$type}-Accepted,
  &.#{$type}-Paid,
  &.#{$type}-Receiptpaid,
  &.#{$type}-Opened,
  &.#{$type}-Open,
  &.#{$type}-success.#{$type}-styled {
    background-color: $success-light;
    color: $success;
  }
  &.#{$type}-Pending,
  &.#{$type}-Sent,
  &.#{$type}-In-Progress,
  &.#{$type}-primary.#{$type}-styled {
    background-color: $info-light;
    color: $info;
  }
  &.#{$type}-Unpaid,
  &.#{$type}-Receiptunpaid,
  &.#{$type}-Terminated,
  &.#{$type}-danger.#{$type}-styled {
    background-color: $danger-light;
    color: $danger;
  }
  &.#{$type}-Suspended,
  &.#{$type}-Answered,
  &.#{$type}-warning.#{$type}-styled {
    background-color: $warning-light;
    color: $warning;
  }
  &.#{$type}-Client-Reply {
    background-color: $gray-400;
    color: $gray-800;
  }
  &.#{$type}-Canceled,
  &.#{$type}-Draft,
  &.#{$type}-Dead,
  &.#{$type}-Cancelled,
  &.#{$type}-Receiptcanceled,
  &.#{$type}-Close,
  &.#{$type}-Closed,
  &.#{$type}-Creditnote,
  &.#{$type}-Fraud,
  &.#{$type}-secondary.#{$type}-styled {
    background-color: theme-light-color('secondary');
    color: $gray-600;
  }
  &.#{$type}-status {
    background-color: $gray-300;
    color: $gray-600;
  }
}

@mixin text-styles($type) {
  &.#{$type}-Active,
  &.#{$type}-Accepted,
  &.#{$type}-Paid,
  &.#{$type}-Receiptpaid,
  &.#{$type}-Opened,
  &.#{$type}-Open {
    color: $success;
  }
  &.#{$type}-Pending,
  &.#{$type}-Sent,
  &.#{$type}-In-Progress,
  &.#{$type}-primary.#{$type}-styled {
    color: $info;
  }
  &.#{$type}-Unpaid,
  &.#{$type}-Receiptunpaid,
  &.#{$type}-Terminated {
    color: $danger;
  }
  &.#{$type}-Suspended,
  &.#{$type}-Answered {
    color: $warning;
  }
  &.#{$type}-Client-Reply {
    color: $purple;
  }
  &.#{$type}-Canceled,
  &.#{$type}-Draft,
  &.#{$type}-Dead,
  &.#{$type}-Cancelled,
  &.#{$type}-Receiptcanceled,
  &.#{$type}-Close,
  &.#{$type}-Closed,
  &.#{$type}-Creditnote,
  &.#{$type}-Fraud {
    color: $secondary;
  }
}

.badge {
  @include badge-styles(badge);
}

.label {
  @include badge-styles(label);
}

.text-status {
  @include text-styles(text);
}

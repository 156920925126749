.upgrade-grid {

  table, thead, tbody, tr, td, th{
    display: block;
  }

  .field-upgrade-row{
    display: grid;
    grid-template-columns: 40% 1fr auto;
    border-bottom: $table-border-width solid $table-border-color;

    .upgrade-name, .upgrade-from{
      grid-column: 1/1;
      width: 100%;
      border: none;
      height: auto;
    }

    .upgrade-name{
      padding-bottom: 0;
    }

    .upgrade-from{
      padding-top: 0;
    }

    .upgrade-to{
      grid-column-start: 2;
      grid-row: 1/4;
      border: none;
    }

    .upgrade-price{
      grid-column-start: 3;
      grid-row: 1/4;
      border: none;
      display: flex;
      align-items: center;
      width: 160px;
    }
  }

  .field-select{
    .upgrade-to > select{
      width: 100%
    }
    .selectqty-box{
      display: flex;
      > select{
        float: none!important;
        width: 100%!important
      }
    }
  }
}

@media(max-width: 1200px) {
  .upgrade-grid {
    .field-upgrade-row{
      .upgrade-name, .upgrade-from, .upgrade-price{
        padding-left: 10px;
      }
      .upgrade-to, .upgrade-price{
        padding-right: 10px;
      }
    }
  }
}

@media(max-width: 767px) {
  .upgrade-grid {
    .field-upgrade-row{
      display: flex;
      flex-direction: column;

      &.field-header{
        display: block;
        flex-direction: row;

        .upgrade-name{
          display: none;
        }

        .upgrade-to, .upgrade-from{
          display: inline-block;
          padding: 15px 0 15px 10px;
          width: auto;
          height: auto;
        }
        .upgrade-from::after{
          content:" /"
        }
        .upgrade-to{
          padding-left: 0!important;
        }
      }

      .upgrade-name, .upgrade-to, .upgrade-from, .upgrade-price{
        padding-left: 10px;
        padding-right: 10px;
      }
      .upgrade-price{
        width: auto;
      }
    }
  }
}